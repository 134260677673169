.card {
    width: 80%;
    border-radius: 12px;
}

.recommandButton {
    margin-top: 2%;
    margin-left: 38%;
}

.resetButton {
    margin-top: 2%;
}

.scanId {
    margin-top: 2%;
    margin-right: 4%;
}

.token {
    margin-top: 2%;
    margin-right: 4%;
}
